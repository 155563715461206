import React from 'react'

import { Link, FormattedMessage } from "gatsby-plugin-intl"

import LrButton from './lrButton'
import LangSwitch from "../components/langSwitch"

const WifiSuccess = () => (
  <div className="wifi-success">
    <div className="my-8">
      <LangSwitch />
    </div>
    <h1 className="wifi-success__title text-4xl font-dancing text-center">
      <FormattedMessage id="connesso" />
    </h1>
    <h2 className="wifi-success__subtitle text-2xl uppercase text-center my-12">
      <FormattedMessage id="scopri_i_piatti" />
    </h2>
    <div className="flex items-center justify-center flex-col">
      <Link to="/menu/" className="block">
        <LrButton>
          <FormattedMessage id="sfoglia_menu" />
        </LrButton>
      </Link>
    </div>
  </div>
)

export default WifiSuccess
