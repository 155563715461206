import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import WifiSuccess from '../components/wifiSuccess'

const ConnectedPage = ({ data: { icon } }) => {
  const { formatMessage } = useIntl()

  return (
    <Layout isLight icon={icon}>
      <SEO title={ formatMessage({ id: 'connesso' }) } />
      <WifiSuccess />
    </Layout>
  )
}

export default ConnectedPage

export const query = graphql`
query ConnectedPageImage {
  icon: file(relativePath: { eq: "casette.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
